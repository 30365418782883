import React from 'react';
import { Row } from 'react-bootstrap';
import * as Styled from './commonBannerStyle';

const CommonBanner = ({ Header, ImageUrl }) => {
  return (
    <Styled.MainContainer>
      <Row className="g-0">
        <Styled.LeftCol xs={12} md={7} lg={6} xl={7}>
          <Styled.HeaderDiv>
            <Styled.Header
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              {Header}
            </Styled.Header>
          </Styled.HeaderDiv>
        </Styled.LeftCol>
        <Styled.RightCol
          $background={ImageUrl}
          md={5}
          lg={6}
          xl={5}
          className="d-none d-md-block"
        ></Styled.RightCol>
      </Row>
    </Styled.MainContainer>
  );
};

export default CommonBanner;
